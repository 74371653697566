<template>
  <b-card header="Topic">
    <b-row class="mb-2">
      <b-col md="6">
        <b-avatar
          :src="`${topic.image}`"
          :text="avatarText(topic.title)"
          size="104px"
          rounded
        />
      </b-col>
    </b-row>
    <b-row class="text-font">
      <b-col md="6">
        Title: <span>{{ topic.title }}</span>
      </b-col>
      <b-col md="6">
        Entity: <span>{{ topic.entity_name }}</span>
      </b-col>
      <b-col md="6">
        Live: <span>{{ topic.live }}</span>
      </b-col>
      <b-col md="6">
        Speaker: <span>{{ topic.speaker }}</span>
      </b-col>

      <b-col md="6">
        Start Date: <span>{{ topic.start_time }}</span>
      </b-col>
      <b-col md="6">
        End Date: <span>{{ topic.end_time }}</span>
      </b-col>
      <b-col md="6">
        Language: <span>{{ topic.languages_data }}</span>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { avatarText } from '@core/utils/filter'

export default {
  name: 'TopicView',
  data() {
    return {
      topic: [],
    }
  },
  created() {
    this.getTopic()
  },
  methods: {
    avatarText,
    getTopic() {
      this.$activities.get(`/internalops/weekly/calendar/topics/${this.$route.params.id}`).then(res => {
        this.topic = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
.text-font {
  font-size: 1.1em;
}
</style>
